export const getMaterials = () => {
    return [
        { enName: "Brass", cnName: "黄铜" },
        { enName: "Ceramic", cnName: "陶瓷" },
        { enName: "Copper", cnName: "铜" },
        { enName: "Silver", cnName: "银" },
        { enName: "Gold", cnName: "金" },
        { enName: "Metal", cnName: "金属" },
        { enName: "Plastic", cnName: "塑料" },
        { enName: "Platinum", cnName: "铂" },
        { enName: "Rubber", cnName: "橡皮" },
        { enName: "Stainless-steel", cnName: "不锈钢" },
        { enName: "Steel", cnName: "钢" },
        { enName: "Sterling-silver", cnName: "纯银" },
        { enName: "Titanium", cnName: "钛" },
        { enName: "Wood", cnName: "木头" },
        { enName: "Aluminum", cnName: "铝" },
        { enName: "Alloy Steel", cnName: "合金钢" },
        { enName: "Palladium", cnName: "钯" },
        { enName: "Acrylonitrile-butadine-styrene", cnName: "丙烯腈-丁二烯-苯乙烯" },
        { enName: "polypropylene", cnName: "聚丙烯" },
        { enName: "Polyvinyl chloride", cnName: "聚氯乙烯" },
        { enName: "Poly-ether-ether-ketone", cnName: "聚醚醚酮" },
        { enName: "Polycarbonate", cnName: "聚碳酸酯" },
        { enName: "Nylon", cnName: "尼龙" },
        { enName: "Cotton", cnName: "棉" },
        { enName: "Wool", cnName: "羊毛" },
        { enName: "Modal", cnName: "模态" },
        { enName: "Polyester", cnName: "聚酯纤维" },
        { enName: "Acrylic", cnName: "丙烯酸纤维" },
        { enName: "Spandex", cnName: "氨纶" },
        { enName: "Lycra", cnName: "莱卡" },
        { enName: "Linen", cnName: "亚麻布" },
        { enName: "Silk", cnName: "丝绸" },
        { enName: "Leather", cnName: "皮革" },
        { enName: "Glass", cnName: "玻璃" },
        { enName: "Mineral", cnName: "矿物" },
        { enName: "Diamond", cnName: "钻石" },
        { enName: "Animal hair", cnName: "动物毛" },
        { enName: "Sapphire", cnName: "蓝宝石" },
        { enName: "Ruby", cnName: "红宝石" },
        { enName: "Agate", cnName: "玛瑙" },
        { enName: "Quartz", cnName: "石英" },
        { enName: "Zinc alloy", cnName: "锌合金" },
    ]
}