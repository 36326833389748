<template>
	<edit :goodsId="productId"></edit>
</template>
<script>
import { reactive, toRefs } from '@vue/reactivity';
import Edit from "./Edit.vue";
import { useRoute } from 'vue-router';

export default {
	name: "product_create",
	components:{
		Edit
	},
	setup() {
		const route = useRoute();

		const state = reactive({
			productId: ''
		})

		state.productId = route.params.id

		return {
			...toRefs(state),
		}
	}
}
</script>